import { ErrorInfo } from 'react'
import { AuthConsumer, AuthProvider } from '@services/Auth'
import { ConnectionProvider } from '@services/Connection'
import { GlobalStyle } from '@theme/GlobalStyle'
import { Theme } from '@theme/Theme'
import { ThemeProvider } from 'styled-components'
import { ENVIRONMENT } from '@root/environment'
import { appWithTranslation } from 'next-i18next'
import App, { AppContext } from 'next/app'
import moment from 'moment'
import sentry from './sentry'
import {
    Agreement,
    Authentication,
    Configuration,
    FunctionGroup,
    Locale,
    Localization,
} from '@tblg/calculator-js-client'

Configuration.init({
    IMAGIN_CUSTOMER: 'tbl',
    IMAGIN_TAILORING: 'tblg',
    CALCULATOR_API_URL: ENVIRONMENT.FACADE_V1.URL as string,
    JATO_SERVICE_API_URL: 'NOT_GONNA_NEED_THIS',
    JATO_SERVICE_API_USERNAME: 'NOT_GONNA_NEED_THIS',
    JATO_SERVICE_API_PASSWORD: 'NOT_GONNA_NEED_THIS',
    MIDOFFICE_API_URL: 'NOT_GONNA_NEED_THIS',
    PAINT_COLOR_SWATCH_API_URL: 'NOT_GONNA_NEED_THIS',
})

Localization.init({
    locale: Locale.nl,
})

Authentication.init({
    token: ENVIRONMENT.FACADE_V1.TOKEN as string,
    functionGroup: new FunctionGroup({
        agreement: new Agreement(),
        fuel: [],
        leaseBudget: {
            default: {
                min: 0,
                max: 9999999,
                norm: 0,
            },
        },
        leaseDuration: {
            default: {
                min: 0,
                max: 120,
            },
        },
        mileage: {
            default: {
                min: 1000,
                max: 120000,
            },
        },
        normLeaseDuration: 0,
        normMileagePerYear: 0,
    }),
})

const { captureException } = sentry()

const PageWithTranslations = appWithTranslation(props => {
    const { Component, pageProps } = props

    return <Component { ...pageProps } />
})

// set all date/time references to current locale
moment.locale(ENVIRONMENT.APP_DEFAULT_LOCALE)
class MyApp extends App {

    public static async getInitialProps(props: AppContext) {
        try {
            return super.getInitialProps(props)
        } catch (error) {
            // Capture errors that happen during a page's getInitialProps.
            // This will work on both client and server sides.
            captureException(error, props.ctx)
            throw error
        }
    }

    public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        captureException(error, { errorInfo })
    }

    public render() {
        return (
            <>
                <GlobalStyle />
                <ThemeProvider theme={ Theme }>
                    <AuthProvider>
                        <AuthConsumer>
                            { ({ accessToken, idToken }) => (
                                <ConnectionProvider accessToken={ accessToken } idToken={ idToken }>
                                    <PageWithTranslations { ...this.props } />
                                </ConnectionProvider>
                            ) }
                        </AuthConsumer>
                    </AuthProvider>
                </ThemeProvider>
            </>
        )
    }
}

export default MyApp
